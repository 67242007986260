import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbAccordionModule, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { data } from 'jquery';
import { DashboardService } from 'src/app/services/dashboard.service';
import { DcProgressService } from 'src/app/services/dc-progress.service';
@Component({
  selector: 'app-dc-timeline-progress',
  templateUrl: './dc-timeline-progress.component.html',
  styleUrls: ['./dc-timeline-progress.component.css']
})
export class DcTimelineProgressComponent implements OnInit {
  
  
  @ViewChild('dctimePopupId', { static: false }) dctimePopupId: ElementRef;
  @ViewChild('dctimeNeedhelp', { static: false }) dctimeNeedhelp: ElementRef;
 
  jsonUrl = '../../../assets/needHelpText/needhelp-text.json';
  
  modalOptions: NgbModalOptions;
  dynamicHelpTitle: string | undefined;
  dynamicHelpContent: string | undefined;
  inProgressStage: number;
  debtCounsellingEndDate: string = null;
  isOldAccount: boolean = false;
  legalConsultant: string = null;
  financialConsultant: string = null;
  negotiationsConsultant: string = null;
  isGetProtectedStepSkipped: boolean = false;
  isInsuranceAgreementReceivedStepSkipped: boolean = false;
  isLegalDocumentationStepSkipped: boolean = false;



  constructor(
    private modalService: NgbModal,
    private http: HttpClient,
    private dcProgressService: DcProgressService,
    private dashboardService: DashboardService,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
      keyboard: false,
      size: 'md',
      centered: true      
    };
    this.fetchDcTimeline();
  }
  openTimePopup(){
    this.modalService.open(this.dctimePopupId,this.modalOptions);
  }

  openNeedHelpPopup(mainIndex: number, needHelpIndex: number) {
    this.http.get<any>(this.jsonUrl).subscribe(
      (data) => {
        const mainKeys = ["APPLICATION", "NEGOTIATIONS","LEGAL PROTECTION","DEBT FREEDOM"];
        const selectedMainKey = mainKeys[mainIndex];
        const selectedSection = data[selectedMainKey];
  
        if (selectedSection) {
          const keys = Object.keys(selectedSection);
          const selectedKey = keys[needHelpIndex];
          const selectedValue = selectedSection[selectedKey];
          this.dynamicHelpTitle = selectedKey;
          this.dynamicHelpContent = selectedValue;
          
          // Open the modal
          this.modalService.open(this.dctimeNeedhelp, this.modalOptions);
        } else {
          console.error('Main section not found.');
        }
      },
      (error) => {
        console.error('Error fetching JSON:', error);
      }
    );
  }

  fetchDcTimeline(){
    var loadData = sessionStorage.getItem('load');  
    if(loadData != null){
      var loadParsed = JSON.parse(loadData);        
      if(loadParsed.dcTimelineResponse != null){
        var dcTimelineData = loadParsed.dcTimelineResponse; 
        this.bindDcTimelineData(dcTimelineData);
      }
      else{
        this.dcProgressService.fetchDcTimelineData().subscribe((data: any) =>{       
          sessionStorage.setItem('dcTimelineData', JSON.stringify(data));
          loadParsed.dCTimelineResponse  = data;   
          sessionStorage.setItem('load', JSON.stringify(loadParsed));
          this.bindDcTimelineData(JSON.parse(data));
        },
        (err: HttpErrorResponse) => {
        });
      }
    }
    else{
      this.dashboardService.Load().subscribe((data: any) => {    
        sessionStorage.setItem('load', JSON.stringify(data));
        if(data.dcTimelineResponse != null){
          var dcTimelineData = data.dcTimelineResponse; 
          this.bindDcTimelineData(dcTimelineData);
        }
      }, (err: HttpErrorResponse) => { });
    }
  }
  
  bindDcTimelineData(data){
    this.inProgressStage = data.inProgressStage ;

    this.legalConsultant = data.legalConsultant;
    this.financialConsultant =data.financialConsultant;
    this.negotiationsConsultant = data.negotiationsConsultant;

    this.isGetProtectedStepSkipped = data.isGetProtectedStepSkipped;
    this.isInsuranceAgreementReceivedStepSkipped = data.isInsuranceAgreementReceivedStepSkipped;
    this.isLegalDocumentationStepSkipped = data.isLegalDocumentationStepSkipped;
 
    if(data.debtCounselingEndDate != null && data.debtCounselingEndDate != ""){
      this.debtCounsellingEndDate = this.getDifferenceInYearsAndMonths(data.debtCounselingEndDate);
    }
  }

  getDifferenceInYearsAndMonths(dateString: string): string {
    const givenDate = new Date(dateString); // Convert the string to a Date object
    const currentDate = new Date(); // Get the current date
    let years; let months;
    const isPast = givenDate <= currentDate;

    if(isPast){
      // Calculate the difference in years and months
      years = currentDate.getFullYear() - givenDate.getFullYear();
      months = currentDate.getMonth() - givenDate.getMonth();
      if (givenDate.getDate() > currentDate.getDate()) {
        months -= 1;
      }
    }
    else{ 
      // Calculate the difference in years and months
      years = givenDate.getFullYear() - currentDate.getFullYear();
      months = givenDate.getMonth() - currentDate.getMonth();

      if (givenDate.getDate() < currentDate.getDate()) {
        months -= 1;
      }
    }
  
    // Adjust for negative months
    if (months < 0) {
      years -= 1;
      months += 12;
    }

    // Format the result
    const differenceString = `${Math.abs(years)} years, ${Math.abs(months)} months`;

    if (isPast) {
      this.isOldAccount = true;
      return `Ended ${differenceString} ago`;
    } else {
      return `${differenceString}`;
    }
  }

  getStatusClass(currStageNumber: number,InProgressStage: number): string {
    if(currStageNumber == InProgressStage){
      var css = 'Panel-Inprogress';
      return css;
    }
    else if(currStageNumber < InProgressStage){
        var css = 'Panel-complete';
        return css;
      }
    else{
      var css = 'Panel-upcoming';
      return css;
      }
  }
  getStatusText(currStageNumber: number,InProgressStage: number): string {
    if(currStageNumber == InProgressStage){
      var status = 'IN PROGRESS';
      return status;
    }
    else if(currStageNumber < InProgressStage){
        var status = 'COMPLETE';
        return status;
      }
    else{
      var status = 'UPCOMING';
      return status;
      }
}

redirectInsurancePage(){
  this.router.navigate(['/portal/insurance'])
}

}
