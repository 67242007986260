import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-needhelp-popup',
  templateUrl: './needhelp-popup.component.html',
  styleUrls: ['./needhelp-popup.component.css']
})
export class NeedhelpPopupComponent implements OnInit {

  modalOptions: NgbModalOptions;
  @Input() needhelpTitle: string;
  @Input() needhelpContent:string
  constructor(
    private modalService: NgbModal
  ) { 
    
  }

  ngOnInit(): void {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
      keyboard: false,
      size: 'md',
      centered: true
      
    };
  }
  closePopup(){
    this.modalService.dismissAll()
  }

}
