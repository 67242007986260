import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class CommunicationService {

  env = environment;

  constructor(private http: HttpClient) { }

  userCallback() {
    return this.http.post(this.env.webApiUrl + 'api/communication/UserCallback', null);
  }

  leadCallback(params: any) {
    return this.http.post(this.env.webApiUrl + 'api/communication/IdmLeadCreate', params);
  }

  TransferUserToOmniLead(params: any){
  	return this.http.post(this.env.webApiUrl + 'api/communication/TransferUserToOmniLead', params);
  }

  IDMLeadautoInsert() {
    return this.http.post(this.env.webApiUrl + 'api/communication/IdmLeadCreateAutoInsert', null);
  }
  IDMLeadWithPlacementInsert(params: any) {
    return this.http.post(this.env.webApiUrl + 'api/communication/IdmLeadCreateWithPlacement', params);
  }

  legalInsuranceLeadCallback() {
    return this.http.post(this.env.webApiUrl + 'api/communication/LegalInsuranceLeadCreate', null);
  }
}
