import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout.component';
import { TermsComponent } from './terms/terms.component';
import { ProductsComponent } from './products/products.component';
import { AuthGuard } from './auth/auth.guard';
import { InsuranceRouteGuard } from './route_guard/insurance.route.guard';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './auth/auth.interceptor';
import { RouterModule, UrlSerializer } from '@angular/router';
import { appRoutes } from './routes';
import { UserService } from './services/user.service';
import { DataService } from './services/data.service';
import { SharedModule } from './shared/shared.module';
import { HomeComponent } from './home/home.component';
import { InputValidationService } from './helpers/validators/input-validation.service';
import { NameFieldDirective } from './helpers/directive/name-field.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ProfileComponent } from './profile/profile.component';
import { CurrencyMaskDirective } from './helpers/directive/currency-mask.directive';
import { SecurityQuestionService } from './services/security-question.service';
import { AccountComponent } from './account/account.component';
import { AccountService } from './services/account.service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MomentModule } from 'angular2-moment';
import { SettingComponent } from './setting/setting.component';
import { CookieService } from 'ngx-cookie-service';
import { NameFieldHyphenSpaceDirective } from './helpers/directive/name-field-hyphen-space.directive';
import { CommunicationService } from './services/communication.service';
import { ChatService } from './services/chat.service';
import { OAuthModule } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';
import { FaqComponent } from './faq/faq.component';
import { FaqService } from './services/faq.service';
import { PolicyComponent } from './policy/policy.component';
import { LoginComponent } from './login/login.component';
import { ParseAccountTypeClassPipe } from './helpers/pipe/parse-account-type-class.pipe';
import { NameFieldWithSpaceDirective } from './helpers/directive/name-field-with-space.directive';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { QuestionnaireStepComponent } from './questionnaire-step/questionnaire-step.component';
import { FinancialWellnessComponent } from './financial-wellness/financial-wellness.component';
import { FinancialWellnessService } from './services/financial-wellness.service';
import { QuestionnaireStepService } from './services/questionnaire-step.service';
import { SecurityQuestion2Component } from './security-question2/security-question2.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MessagingService } from './services/messaging.service';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';

import { InsuranceComponent } from './insurance/insurance.component'; 
import { RegisterAComponent } from './register-a/register-a.component';

import { SecurityQuestionComponent } from './security-question/security-question.component'; 
import { TrackingDirective } from './helpers/directive/tracking.directive';
import { DatePipe } from '@angular/common';
import { CurrencyPipe } from '@angular/common';
import { CellNumberUpdateComponent } from './cell-number-update/cell-number-update.component';
import { PaymentComponent } from './payment/payment.component';
import { SmartcentsComponent } from './registration/smartcents/smartcents.component';
import { RegisterBaseComponent } from './register/register-base.component';
import { BureauDataProvidedModule } from './bureau-data-provided/bureau-data-provided.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppInsightsService } from './services/appinsights.service';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { RegistrationService } from './services/registration.service';
import { SapiRegisterComponent } from './sapi/sapi-register.component';
import { FormatTimePipe } from './helpers/pipe/format-time.pipe';
import { SapiService } from './services/sapi.service';
import { AutoIdDirective } from './helpers/directive/auto-id-buttons.directive';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { ReferralProgrammeComponent } from './referral-programme/referral-programme.component';

export const customCurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  allowZero: true,
  decimal: ',',
  precision: 0,
  prefix: 'R ',
  suffix: '',
  thousands: ',',
  nullable: true
};


@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    TermsComponent,
    AutoIdDirective ,
    ProductsComponent,
    HomeComponent,
    NameFieldDirective,
    ProfileComponent,
    SettingComponent,
    CurrencyMaskDirective,
    AccountComponent,
    NameFieldHyphenSpaceDirective,
    NameFieldWithSpaceDirective,
    FaqComponent,
    PolicyComponent,
    LoginComponent,
    ParseAccountTypeClassPipe,
    QuestionnaireComponent,
    QuestionnaireStepComponent,
    FinancialWellnessComponent,
    SecurityQuestionComponent,
    InsuranceComponent,
    RegisterAComponent,
    SecurityQuestion2Component, 
    TrackingDirective, 
    CellNumberUpdateComponent,
    PaymentComponent,
    SmartcentsComponent,
    RegisterBaseComponent,
    SapiRegisterComponent,
    ReferralProgrammeComponent,
    UpdatePasswordComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule.forRoot(appRoutes , { scrollPositionRestoration: "enabled" }),
    NgbModule,
    NgxSpinnerModule,
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    AngularFireMessagingModule, 
    AngularFireModule.initializeApp(environment.firebase),
    CarouselModule ,
    OAuthModule.forRoot({
      resourceServer: {
          allowedUrls: [environment.webApiUrl],
          sendAccessToken: true
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.enableServiceWorker }) ,
	  BureauDataProvidedModule,
    BrowserAnimationsModule,
    MalihuScrollbarModule.forRoot()
  ],
  exports: [
    CurrencyMaskDirective
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [
    AuthGuard,
    InsuranceRouteGuard,
    SecurityQuestionService,
    UserService,
    DataService,
    AccountService,
    CookieService,
    CommunicationService,
    InputValidationService,
    ChatService,
    FaqService,
    FinancialWellnessService,
    QuestionnaireStepService,
    CurrencyPipe,
    MessagingService,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
     },
     DatePipe,
     AppInsightsService,
     RegistrationService,
     FormatTimePipe,
     SapiService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
