export namespace Enum {
    export enum CreditScoreStatusType {
        NoScore = 1,
        VeryHighRisk,
        HighRisk,
        MediumHighRisk,
        MediumRisk,
        MediumLowRisk,
        LowRisk,
        VeryLowRisk
    }

    export enum OtpType {
        Sms = 1,
        Email = 2
    }

    export enum DCTimelineStages {
        Form17_1_Sent = 1,
        Form17_2_Accept_Sent = 2,
        Payment_Schedule_Sent = 3,
        Payment_Plan_Check = 3,
        Assigned_to_Negotiations = 4,
        Negotiations_Agreement_Sent = 5,
        Negotiations_Agreement_Accepted = 6,
        Final_Proposal_Sent = 7,
        Negotiations_Finalised = 8,
        Negotiations_Finalised_Full_Acceptance_Only = 8,
        Negotiations_Finalised_Non_Full_Acceptance_Only = 8,
        Assigned_to_Legal = 9,
        NCT = 9,
        Magistrate = 9,
        Support_Docs_Sent = 10,
        Case_Number_Received = 11,
        Full_Pack_Sent_to_Attorney = 11,
        Consent_Order_Received = 12,
        Legal_Protection_Granted = 12,
        Court_Order_Received = 12,
        Your_Journey_To_Debt_Freedom = 13,
        Clearance_Pending = 14,
        Clearance = 15
    }

    export enum DCTimelineUIStages{
        Debt_Counselling_Application_Sent_To_Credit_Providers = 1,
        Debt_Rearrangement_Proposal_Approved = 2,
        Debt_Counselling_Payment_Plan = 3,
        Credit_Negotiations_And_Financial_Analysis = 4,
        Get_Protected = 5,
        Insurance_Agreement_Received = 6,
        Debt_Restructuring_Proposal = 7,
        Credit_Negotiations_Completed = 8,
        Application_For_Legal_Protection = 9,
        Legal_Documentation = 10,
        Legal_Application_Sent = 11,
        Legal_Protection_Granted = 12,
        Your_Journey_To_Debt_Freedom = 13,
        Clearance_Certificate_Pending = 14,
        Clearance_Certificate_Issued = 15
    }
    
    export enum DCTimelineStageStatus
    {
        Completed =1 ,
        Active =2,
        Skipped =3,
        Upcoming = 4
    }
}
