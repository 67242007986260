<section class="financial-wellness b5containeradjust">
    <div class="container">
        <div class="pt-md-4 pt-5 pb-md-0 pb-4  col-md-8 px-lg-3 wlnessscor mx-auto">
            <div class="position-relative">
               
            <h2 class="section-caption text-center mb-md-4 mb-2">Your Financial Wellness Summary</h2>
            <div class="financial-progress d-flex justify-content-between"><span class="financial-progress-bar"
                    [style.width]="wellnessProgress + '%'">{{wellnessProgress}}% Complete</span> </div>
            </div>
            <span *ngIf="!(isSolution&&wellnessProgressSolution)"
                class="complete-text d-block mb-md-3 text-center">{{progressBarStatus}}</span>
            <span class="complete-text d-block mb-md-5  text-center"></span>
        </div>
        <div class=" pt-0 pb-md-5  px-lg-0">

            <div class="d-flex flex-wrap justify-content-center align-items-stretch">
                
                <!--<div class="col-md-12 px-0 d-flex flex-wrap justify-content-center">-->
                    
                    <!--<div class="col-md-2 align-self-center d-none d-md-none d-lg-block">
                        <ul class="scorelist">
                            <li>Target Score</li>
                            <li>Your Score</li>
                        </ul>
                    </div>
                </div>-->
                <!-- Modal -->
                <div class="col-md-12 col-12 mb-4 px-md-3 px-0 d-flex flex-wrap justify-content-center" id="debt-solution" style="display: none">
                    <div class="col-md-8 col-12 mb-md-5 d-flex flex-wrap shadow-d ps-md-3">
                        
                        <div class="sol-box">
                            <p class="p-textg">
                                <!-- DebtBusters has a solution for you!
                                <br/> -->
                            We can save you money!
                            </p>

                            <!-- <span *ngIf="!isSolution" class="button-holder w-100 d-block text-center pt-3 pb-3">
                                <button class="find-more btn" (click)="onSelect()" name="btnFinancialWellness_ViewSolution">View Solution</button>

                            </span> -->

                            <div class="pop-holder mt-auto" id="solution">
                                <div class="pop-holder">
                                    <!-- <h4 class="section-caption ">DebtBusters could possibly reduce your monthly instalment</h4> -->
                                    <div class="pay-history">
                                        <div class="d-flex mb-3 pay-row justify-content-between">
                                            <div class="paytext">You are currently paying</div>
                                            <div class="payammount">R {{monthlyInstallment}}</div>
                                        </div>
                                        <div class="d-flex mb-3 pay-row justify-content-between">
                                            <div class="paytext">You could be paying</div>
                                            <div class="payammount text-green"><strong>R {{ dbC_DRS}}</strong></div>
                                        </div>
                                        <div class="d-flex mb-5 pay-row justify-content-between bg-green">
                                            <div class="paytext text-white">Monthly saving</div>
                                            <div class="payammount text-white"><strong>R {{((monthlyInstallment - dbC_DRS)<=0) ? 0 : (monthlyInstallment - dbC_DRS)}}</strong></div>
                                        </div>
                                    </div>
                                    <!-- <p class="text-sm">You could only pay <span class="text-green">R
                                        {{dbC_DRS}}</span> each month for all of your credit.You currently pay
                                        <span class="text-red">R {{monthlyInstallment}}</span>.</p>
        
                                    <h4 class="section-caption ">DebtBusters could help create savings for you</h4>
                                    <p class="text-sm">You could free up <span class="text-green">R
                                            {{monthlyInstallment - dbC_DRS}}</span> extra for your
                                        living expenses. After paying for credit you currently have <span class="text-red">R
                                            {{grossIncome - monthlyInstallment}} </span> for your
                                        expenses, that could increase to <span class="text-green">R
                                            {{grossIncome - dbC_DRS}}</span>.</p>
        
                                    <h4 class="section-caption mb-3">We save you <span class="text-green">R
                                            {{((monthlyInstallment - dbC_DRS)<=0) ? 0 : (monthlyInstallment - dbC_DRS)}}</span> every month!</h4> -->
                                    <button *ngIf="dbC_DRS >= 4000" id="dButton-financial-wellness-Call_me" class="find-more btn popoverclose"  (click)="directCallbackPopup('Callback')" 
                                         name="btnFinancialWellness_CallMeToDiscuss">Call me</button>
                                    <button *ngIf="dbC_DRS < 4000" id="dButton-financial-wellness-Call_me" class="find-more btn popoverclose" data-bs-toggle="modal"
                                        data-bs-target="#staticBackdrop" name="btnFinancialWellness_CallMeToDiscuss">Call me</button>
        
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
                <div class="col-md-6 col-12 mb-md-5 mb-4 flex-wrap d-flex align-items-stretch shadow-d">
                    
                    <div class="top-setion bhvindicator w-100"
                        *ngIf="surveyAnswered.answered < surveyAnswered.totalQuestions; else elseBlock">
                        <h2 class="section-caption text-center mb-3">Financial Behaviour Indicator</h2>
                        <p class="survey-text text-center mb-0 w-100">
                            You have not completed our fun and easy survey yet, please complete now and see how your
                            results compare to our other users.
                        </p>

                        <div class="question-solved w-100" *ngIf="surveyAnswered.answered > 0;">
                            <!--<span class="answ-text mb-1">Answered {{surveyAnswered.answered}} out of
                                {{surveyAnswered.totalQuestions}}</span>-->
                            <div class="survey-progress">
                                <span class="survey-progress-bar"
                                    [style.width]="surveyAnswered.answered/surveyAnswered.totalQuestions * 100 + '%'"></span>
                            </div>
                            <span class="answ-text mb-1">Answered {{surveyAnswered.answered}} out of
                                {{surveyAnswered.totalQuestions}}</span>
                        </div>
                    </div>
                    <span *ngIf="surveyAnswered.answered < surveyAnswered.totalQuestions;"
                        class="button-holder w-100 d-block text-center pt-4 mt-auto d-flex w-100 align-items-end justify-content-center">
                        <a id="dAnchor-financial-wellness-Complete
                        " href="javascript:void(0)" routerLink="/portal/questionnaire-step"
                            class="find-more btn">Complete</a>
                    </span>

                    <ng-template #elseBlock>
                        <div class="top-setion bhvindicator w-100">
                            <h2 class="section-caption text-center mb-3">Financial Behaviour Indicator</h2>
                            <!-- <div class="text-center">
                                <div class="graph-hld">
                                    <img src="../assets/images/circle-bg.png" />
                                    <span class="value">{{surveyAnswered.surveyPercentage}}%</span>
                                    <span class="dial" [style.transform]="'rotate(' + degree + 'deg)'"></span>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" height="600" width="600"
                                        class="circular-chart ">
                                        <defs>
                                            <linearGradient id="Gradient3" gradientTransform="rotate(136)">
                                                <stop offset="0%" stop-color="#F8D926" />
                                                <stop offset="12%" stop-color="#F8D926" />
                                                <stop offset="30%" stop-color="#BAD44A" />
                                                
                                                <stop offset="50%" stop-color="#58A331" />
                                            </linearGradient>
                                            <linearGradient id="Gradient4" gradientTransform="rotate(96)">

                                                <stop offset="0%" stop-color="#F90606" />
                                                <stop offset="20%" stop-color="#F90606" />
                                                <stop offset="90%" stop-color="#F58726" />
                                                <stop offset="93%" stop-color="#F8D926" />
                                                <stop offset="100%" stop-color="#F8D926" />
                                            </linearGradient>
                                            <pattern id="Pattern2" x="0" y="0" width="600" height="600"
                                                patternUnits="userSpaceOnUse">
                                                <g class="trnfr" transform="rotate(0, 300, 300)">
                                                    <rect shape-rendering="crispEdges" class="gredwdth" x="0" y="-20"
                                                        width="300" height="600" fill="url(#Gradient3)" />
                                                    <rect shape-rendering="crispEdges" class="gredwdth" x="300" y="-20"
                                                        width="300" height="600" fill="url(#Gradient4)" />
                                                </g>
                                            </pattern>
                                        </defs>
                                        <path id='arc5' class="animated" style="stroke: url(#Pattern2);"
                                            fill='transparent'
                                            attr.stroke-dasharray="{{  surveyAnswered.surveyPercentage * 12 + 370 + ' ' + (1570)}}"
                                            stroke-width='45' d='M 300 58 A 250 250 0 1 1 299.99 58' />


                                    </svg>
                                </div>
                            </div> -->
                            <div class="text-center gaugeholder">
                                <div class="gauge" #gaugeValueFinancial>
                                    <div class="needle">
                                    <div class="needle-pointer">
                                        <svg width="70" height="75" viewBox="0 0 98 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_901_22)">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.61478 91.0662L3.69478 85.9563C2.40132 84.6139 1.40684 83.0129 0.776797 81.2585C0.146759 79.504 -0.104543 77.6361 0.0394311 75.7775C0.183406 73.919 0.719393 72.1121 1.61211 70.4756C2.50482 68.8392 3.73402 67.4104 5.21878 66.2832L91.3708 0.833248C92.1679 0.227181 93.1598 -0.0650169 94.1581 0.0121572C95.1565 0.0893313 96.0917 0.530501 96.7862 1.25187C97.4806 1.97323 97.886 2.92455 97.9252 3.9251C97.9644 4.92566 97.6347 5.90576 96.9988 6.67925L28.3298 90.2933C27.1469 91.7337 25.6724 92.9073 24.0033 93.7368C22.3341 94.5663 20.5083 95.0329 18.6458 95.1059C16.7834 95.179 14.9266 94.8567 13.1976 94.1604C11.4687 93.4642 9.90682 92.4096 8.61478 91.0662Z" fill="#2B2B2B"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_901_22">
                                        <rect width="97.969" height="95.156" fill="white"/>
                                        </clipPath>
                                        </defs>
                                        </svg>
                                    </div>
                                    <div class="needle-circle">
                                        <svg width="40" height="40" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M32.9096 65.7941C32.697 65.7941 32.484 65.7921 32.2706 65.7881C23.5743 65.622 15.2976 62.0189 9.25058 55.7669C3.2036 49.5148 -0.121535 41.1226 0.00236585 32.4255C0.126267 23.7284 3.68912 15.4343 9.91175 9.35705C16.1344 3.27978 24.5104 -0.0860745 33.2079 -0.00440721C41.9055 0.07726 50.2168 3.5998 56.3242 9.79284C62.4316 15.9859 65.8381 24.3454 65.7987 33.0433C65.7593 41.7412 62.2771 50.0695 56.1138 56.2069C49.9505 62.3443 41.6076 65.7913 32.9096 65.7941V65.7941ZM32.8856 15.4641C28.3801 15.4539 24.0483 17.2019 20.8116 20.3361V20.3361C17.9092 23.1318 16.0658 26.8473 15.5956 30.8496C15.1254 34.852 16.0574 38.8935 18.233 42.2857C20.4085 45.6779 23.6929 48.2108 27.5266 49.453C31.3603 50.6951 35.506 50.5695 39.2575 49.0977C43.009 47.6259 46.1341 44.8988 48.1004 41.3812C50.0667 37.8636 50.7525 33.7731 50.0409 29.8065C49.3294 25.8399 47.2646 22.2428 44.1982 19.6279C41.1319 17.0131 37.2538 15.5423 33.2246 15.4661C33.1126 15.4671 32.9986 15.4641 32.8866 15.4641H32.8856Z" fill="#2B2B2B"/>
                                        </svg>
                                    </div>
                                    </div>
                                    <div class="labels">
                                        <div class="value-label">{{this.surveyAnswered.surveyPercentage}}%</div>
                                    </div>
                                </div>
                            </div>
                            <div class="gauge-status w-100 text-center text-green">
                                {{surveyStatus}}
                            </div>
                        </div>
                        <span
                            class="button-holder w-100 d-block text-center pt-4 mt-auto d-flex w-100 align-items-end justify-content-center">
                            <button id="dButton-financial-wellness-Find_out_more" class="find-more btn mx-1" data-bs-target="#survyresult" (click)="getSurveyIndicator()"
                                data-bs-toggle="modal" name="btnFinancialWellness_FinancialBehaviourIndicator_FindOutMore_Type{{type}}">Find out more</button>

                          <button class="find-more btn mx-1" id="btn-showCallMeTOPotentialCustomers-FinancialWellness" (click)="showCallMeTOPotentialCustomers()"
                                *ngIf="potentialUserAsPerSurveyQNA"  name="PotentialCustomers_CallMeButtonClicked">Call Me</button>
                        </span>
                    </ng-template>

                    <!--
                    <div class="text-center">
                        <div class="graph-hld">
                            <img src="../assets/images/circle-bg.png"/>
                              <span class="value">50%</span>
                              <span class="dial" style="transform: rotate(-53deg)"></span>
                              <svg viewBox="-2 -2 40 40" class="circular-chart">
                                <defs>
                                    <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                                        <stop offset="0%" class="inner-start-liner" stop-color="#F90606" />
                                        <stop offset="25%" class="inner-start-liner" stop-color="#F58726" />
                                        <stop offset="50%" class="inner-start-liner" stop-color="#F8D926" />
                                        <stop offset="75%" class="inner-start-liner" stop-color="#9ED25B" />
                                        <stop offset="100%" class="inner-stop-liner" stop-color="#9dd15b" />
                                    </linearGradient>
                                </defs>
                                
                                <path class="circle" stroke="url(#gradient)"
                                 stroke-dasharray="36.5 100"
                                  d="M18 2.0845
                                    a 15.9155 15.9155 0 0 1 0 31.831
                                    a 15.9155 15.9155 0 0 1 0 -31.831"
                                />
                              </svg>
                          </div>
                    </div>
                    <span class="profile-status">
                        Very low risk
                    </span>-->
                </div>
                <div class="col-md-6 col-12 mb-md-5 mb-4 d-flex flex-wrap align-items-stretch shadow-d" id="debt-consolidation-indicator">
                    <div v class="top-setion w-100">
                        <h2 class="section-caption text-center mb-4">Debt Consolidation Indicator
                        <svg xmlns="http://www.w3.org/2000/svg"  type="button" id="btn_debtConsolidationIndicator_infoPopUp_solutionsPage" (click)="debtConsolidationIndicatorPopup()"  width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                            <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
                        </svg>
                        
                        </h2>
                        <div class="text-center gaugeholder">
                            <div class="gauge" #gaugeValue>
                                <div class="needle">
                                <div class="needle-pointer">
                                    <svg width="70" height="75" viewBox="0 0 98 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_901_22)">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.61478 91.0662L3.69478 85.9563C2.40132 84.6139 1.40684 83.0129 0.776797 81.2585C0.146759 79.504 -0.104543 77.6361 0.0394311 75.7775C0.183406 73.919 0.719393 72.1121 1.61211 70.4756C2.50482 68.8392 3.73402 67.4104 5.21878 66.2832L91.3708 0.833248C92.1679 0.227181 93.1598 -0.0650169 94.1581 0.0121572C95.1565 0.0893313 96.0917 0.530501 96.7862 1.25187C97.4806 1.97323 97.886 2.92455 97.9252 3.9251C97.9644 4.92566 97.6347 5.90576 96.9988 6.67925L28.3298 90.2933C27.1469 91.7337 25.6724 92.9073 24.0033 93.7368C22.3341 94.5663 20.5083 95.0329 18.6458 95.1059C16.7834 95.179 14.9266 94.8567 13.1976 94.1604C11.4687 93.4642 9.90682 92.4096 8.61478 91.0662Z" fill="#2B2B2B"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_901_22">
                                    <rect width="97.969" height="95.156" fill="white"/>
                                    </clipPath>
                                    </defs>
                                    </svg>
                                </div>
                                <div class="needle-circle">
                                    <svg width="40" height="40" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M32.9096 65.7941C32.697 65.7941 32.484 65.7921 32.2706 65.7881C23.5743 65.622 15.2976 62.0189 9.25058 55.7669C3.2036 49.5148 -0.121535 41.1226 0.00236585 32.4255C0.126267 23.7284 3.68912 15.4343 9.91175 9.35705C16.1344 3.27978 24.5104 -0.0860745 33.2079 -0.00440721C41.9055 0.07726 50.2168 3.5998 56.3242 9.79284C62.4316 15.9859 65.8381 24.3454 65.7987 33.0433C65.7593 41.7412 62.2771 50.0695 56.1138 56.2069C49.9505 62.3443 41.6076 65.7913 32.9096 65.7941V65.7941ZM32.8856 15.4641C28.3801 15.4539 24.0483 17.2019 20.8116 20.3361V20.3361C17.9092 23.1318 16.0658 26.8473 15.5956 30.8496C15.1254 34.852 16.0574 38.8935 18.233 42.2857C20.4085 45.6779 23.6929 48.2108 27.5266 49.453C31.3603 50.6951 35.506 50.5695 39.2575 49.0977C43.009 47.6259 46.1341 44.8988 48.1004 41.3812C50.0667 37.8636 50.7525 33.7731 50.0409 29.8065C49.3294 25.8399 47.2646 22.2428 44.1982 19.6279C41.1319 17.0131 37.2538 15.5423 33.2246 15.4661C33.1126 15.4671 32.9986 15.4641 32.8866 15.4641H32.8856Z" fill="#2B2B2B"/>
                                    </svg>
                                </div>
                                </div>
                                <div class="labels">
                                    <div class="value-label">{{this.debtConsolidationPercentage}}%</div>
                                </div>
                            </div>
                            <!-- <div class="graph-hld">
                                <img src="../assets/images/circle-bg.png" />
                                <span class="value">{{debtConsolidationPercentage}}%</span>
                                <span class="dial" [style.transform]="'rotate(' + debtConsolidationdegree + 'deg)'"></span>
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" height="600" width="600"
                                    class="circular-chart ">
                                        <defs>
                                            <linearGradient id="Gradient1" gradientTransform="rotate(134)">
                                                
                                                <stop offset="0%" stop-color="#F8D926" />
                                                <stop offset="30%" stop-color="#BAD44A" />
                                                <stop offset="50%" stop-color="#58A331" />
                                            </linearGradient>
                                            <linearGradient id="Gradient2" gradientTransform="rotate(96)">
    
                                                <stop offset="0%" stop-color="#F90606" />
                                                <stop offset="50%" stop-color="#F90606" />
                                                <stop offset="90%" stop-color="#F58726" />
                                                <stop offset="93%" stop-color="#F8D926" />
                                                <stop offset="100%" stop-color="#F8D926" />
                                                
                                            </linearGradient>
                                            <pattern id="Pattern" x="0" y="0" width="600" height="600"
                                                patternUnits="userSpaceOnUse">
                                                <g class="trnfr" transform="rotate(0, 300, 300)">
                                                    <rect shape-rendering="crispEdges" class="gredwdth" x="0" y="-20"
                                                        width="300" height="600" fill="url(#Gradient1)" />
                                                    <rect shape-rendering="crispEdges" class="gredwdth" x="300" y="-20"
                                                        width="300" height="600" fill="url(#Gradient2)" />
                                                </g>
                                            </pattern>
                                        </defs>
                                        <path id='arc4' class="animated" style="stroke: url(#Pattern);" fill='transparent'
                                            attr.stroke-dasharray="{{  debtConsolidationPercentage * 12 + 370 + ' ' + (1570)}}" stroke-width='45'
                                            d='M 300 58 A 250 250 0 1 1 299.99 58' />   

                                </svg>
                            </div> -->
                        </div>
                        <!-- <span class="profile-status">
                            {{debtConsolidationStatus}}
                        </span> -->
                    </div>
                    <div class="gauge-status w-100 text-center text-green">
                        {{debtConsolidationStatus}}
                    </div>
                    <!-- <span class="button-holder w-100 d-block text-center  mt-auto pt-4">
                        <button id="dButton-financial-wellness-See_credit_report" class="find-more btn" data-bs-toggle="modal" data-bs-target="#findmore" name="btnFinancialWellness_DebtConsolidationIndicator_FindOutMore">See credit report</button>
                    </span> -->

                    <span class="button-holder w-100 d-block text-center  mt-auto pt-4">
                        <button id="dButton-financial-wellness-See_credit_report" class="find-more btn" name="btnFinancialWellness_DebtConsolidationIndicator_FindOutMore"  routerLink="/portal/account">See credit report</button>
                    </span>
                </div>
                
                <div class="col-md-12 col-12 px-0 d-flex flex-wrap justify-content-center">
                    <div class="col-md-12 col-12">
                        <h2 class="section-caption text-center mb-3">DebtBusters Debt Radar</h2>
                    </div>
                    <div class="col-md-12 col-12 shadow-allview mb-md-0 mb-4">
                        <div class="col-md-12 col-12 d-flex flex-wrap px-0">
                            <div class="col-md-3 px-lg-3 px-0  text-center d-none d-md-block">
                                <div class="scorelistouter">
                                    <h5 class="kecaption">Key</h5>
                                    <ul class="scorelist text-center">
                                        <li><span>Your Score</span></li>
                                        <li><span>Target</span></li>
                                        <li><span>High Risk</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-6 col-12 px-0 d-flex flex-wrap align-items-stretch  chartcontainer">
                                <div class="top-setion w-100">
                                    <!--deskop chart-->
                                    <div class="desktopchart d-lg-block d-none">
                                        <div class="lbl">
                                            <div class="lbl-row position-relative d-flex justify-content-between">
                                                <span class="lblxt-8">Unsecured <br/> to total <br/> debt</span>
                                                <span class="lblxt-1">Credit <br/> score</span>
                                                <span class="lblxt-2">Salary <br/> toward <br/> Debt</span>
                                            </div>
        
                                            <div class="lbl-row2 position-relative d-flex justify-content-between">
                                                <span class="lblxt-7">Age <br/> youngest <br/> account</span>
                                                <span class="lblxt-3">Open <br/> accounts</span>
                                            </div>
                                            
                                            <div class="lbl-row3 position-relative d-flex justify-content-between">
                                                <span class="lblxt-6">Age 12 <br/> months <br/> account</span>
                                                <span class="lblxt-4">Arrears <br/> group</span>
                                            </div>
                                            
                                            <div class="lbl-row4 position-relative d-flex justify-content-center">
                                                <span class="lblxt-5">Paid-off</span>
                                            </div>
                                            
                                            
                                            
                                            
                                            
                                        </div>
                                        <canvas id="myChart" class="" width="100%" height="100%"></canvas>
                                    </div>
                                    <!--mobile chart-->
                                    <div class="mobilechart position-relative d-lg-none d-block">
                                        <div class="lbl">
                                            <div class="lbl-row position-relative d-flex justify-content-between">
                                                <span class="lblxt-8">Unsecured <br/> to total <br/> debt</span>
                                                <span class="lblxt-1">Credit <br/> score</span>
                                                <span class="lblxt-2">Salary <br/> toward <br/> debt</span>
                                            </div>
        
                                            <div class="lbl-row2 position-relative d-flex justify-content-between">
                                                <span class="lblxt-7">Age <br/> youngest <br/> account</span>
                                                <span class="lblxt-3">Open <br/> accounts</span>
                                            </div>
                                            
                                            <div class="lbl-row3 position-relative d-flex justify-content-between">
                                                <span class="lblxt-6">Age 12 <br/> months <br/> account</span>
                                                <span class="lblxt-4">Arrears <br/> group</span>
                                            </div>
                                            
                                            <div class="lbl-row4 position-relative d-flex justify-content-center">
                                                <span class="lblxt-5">Paid-off</span>
                                            </div>
                                        </div>
                                        <canvas id="chartMobile" class="" width="100%" height="100%"></canvas>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 px-0">
                            <div class="cchartpercentage">{{radarPercent}}%</div>
                            <span class="profile-status w-100" [innerHTML]="radarStatus">
                                
                            </span>
                            <div class="col-md-3 px-lg-3 px-0 mt-2 d-md-none d-block text-center">
                                <div class="scorelistouter">
                                    <h5 class="kecaption">Key</h5>
                                    <ul class="scorelist text-center">
                                        <li><span>Your Score</span></li>
                                        <li><span>Target</span></li>
                                        <li><span>High Risk</span></li>
                                    </ul>
                                </div>
                            </div>
                            <span class="button-holder w-100 d-block text-center  mt-auto pt-3" data-bs-target="#findoutmore" data-bs-toggle="modal">
                                <button id="dButton-financial-wellness-Find_out_more" class="find-more btn" (click)="sendGAEvent(type,'DebtBustersDebtRadar_FindOutMore')" name="btnFinancialWellness_DebtBustersDebtRadar_FindOutMore_Type{{type}}">Find out more</button>
                            </span>
                        </div>
                    </div>
                    
                    
                </div>
                
            </div>

        </div>

        <div class="dashboard-btn-list d-flex col-lg-9 col-12 px-0 mx-auto justify-content-between">
             
                <a id="dAnchor-financial-wellness-View_your_full
                Credit_Profile" class="btn-green btn mx-md-3" href="javascript:void(0)" routerLink="/portal/dashboard">
                   <img src="../../assets/images/credit-report.png" class="me-2" alt=""/>
                    <span id="dAnchor-financial-wellness-View_your_full
                    Credit_Profile-Span1" class="report-text">View your full <br/> Credit Profile</span>
                </a>
           

           
                
                <a id="dAnchor-financial-wellness-View_your_full
                Credit_Report" class="btn-green btn mx-md-3" href="javascript:void(0)"  [ngClass]="{ 'show-scrollTop': windowScrolled }" routerLink="/portal/account">
                    <img src="../../assets/images/credit-report.png" class="me-2" alt=""/>
                    <span id="dAnchor-financial-wellness-View_your_full
                    Credit_Report-Span1" class="report-text">View your full <br/> Credit Report</span>
                </a>
            

        </div>


    </div>
    <section class="vccbsection mt-md-5" >
        <app-bureau-data-provided></app-bureau-data-provided>
    </section>
</section>




<!-- Modal -->
<div class="modal fade" id="findoutmore" data-backdrop="static" data-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">How this works</h5>
                <button id="dButton-financial-wellness-×-1" type="button" class="close" data-bs-dismiss="modal" aria-label="Close" name="btnFinancialWellness_DebtConsolidationIndicatorClose">
                    <span id="dButton-financial-wellness-×-1-Span1" aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>
                    DebtBusters has calculated an unique score, just for you, that you can track over time.<br /> Making use of the below indicators, we try and show you which areas carry some risk and which areas you might want to try and improve on.
                    <br />
                    You can't always be in the green, 100% of the time, but it certainly helps knowing what to keep a close eye on!
                </p>

                <ul class="indicators">
                    <li class="">
                        <div class="col-4">
                            <strong>What do the indicators mean</strong>
                        </div>
                        
                        <div class="col-8">
                            <strong>Description</strong>
                        </div>
                    </li>
                    <li class="">
                        <div class="col-md-4 col-12 d-flex mb-md-0 mb-2">
                            <div class="col-6 p-0 d-md-none d-block ">
                                <strong>What do the indicators mean:</strong>
                            </div>
                            <div class="col-md-12 col-6 p-0">
                                Credit bureau score
                            </div>
                        </div>
                        
                        <div class="col-md-8 col-12 d-flex mb-md-0 mb-2">
                            <div class="col-6 p-0 d-md-none d-block ">
                                <strong>Description:</strong>
                            </div>
                            <div class="col-md-12 col-6 p-0">
                                Your credit bureau score as displayed on your Dashboard page.
                            </div>
                        </div>
                        
                    </li>


                    <li class="">
                        <div class="col-md-4 col-12 d-flex mb-md-0 mb-2">
                            <div class="col-6 p-0 d-md-none d-block ">
                                <strong>What do the indicators mean:</strong>
                            </div>
                            <div class="col-md-12 col-6 p-0">
                                Debt to income ratio
                            </div>
                        </div>
                        
                        <div class="col-md-8 col-12 d-flex mb-md-0 mb-2">
                            <div class="col-6 p-0 d-md-none d-block ">
                                <strong>Description:</strong>
                            </div>
                            <div class="col-md-12 col-6 p-0">
                                How much of your income is used on debt repayments, as displayed on your Dashboard page.
                            </div>
                        </div>
                        
                    </li>

                    <li class="">
                        <div class="col-md-4 col-12 d-flex mb-md-0 mb-2">
                            <div class="col-6 p-0 d-md-none d-block ">
                                <strong>What do the indicators mean:</strong>
                            </div>
                            <div class="col-md-12 col-6 p-0">
                                Open accounts
                            </div>
                        </div>
                        
                        <div class="col-md-8 col-12 d-flex mb-md-0 mb-2">
                            <div class="col-6 p-0 d-md-none d-block ">
                                <strong>Description:</strong>
                            </div>
                            <div class="col-md-12 col-6 p-0">
                                How many open accounts you currently have.
                            </div>
                        </div>
                        
                    </li>

                    <li class="">
                        <div class="col-md-4 col-12 d-flex mb-md-0 mb-2">
                            <div class="col-6 p-0 d-md-none d-block ">
                                <strong>What do the indicators mean:</strong>
                            </div>
                            <div class="col-md-12 col-6 p-0">
                                Arrears
                            </div>
                        </div>
                        
                        <div class="col-md-8 col-12 d-flex mb-md-0 mb-2">
                            <div class="col-6 p-0 d-md-none d-block ">
                                <strong>Description:</strong>
                            </div>
                            <div class="col-md-12 col-6 p-0">
                                Any overdue amounts on your open accounts.
                            </div>
                        </div>
                        
                    </li>

                    <li class="">
                        <div class="col-md-4 col-12 d-flex mb-md-0 mb-2">
                            <div class="col-6 p-0 d-md-none d-block ">
                                <strong>What do the indicators mean:</strong>
                            </div>
                            <div class="col-md-12 col-6 p-0">
                                Paid off
                            </div>
                        </div>
                        
                        <div class="col-md-8 col-12 d-flex mb-md-0 mb-2">
                            <div class="col-6 p-0 d-md-none d-block ">
                                <strong>Description:</strong>
                            </div>
                            <div class="col-md-12 col-6 p-0">
                                How much you have paid off, comparing your original balance to your current balance.
                            </div>
                        </div>
                        
                    </li>

                    <li class="">
                        <div class="col-md-4 col-12 d-flex mb-md-0 mb-2">
                            <div class="col-6 p-0 d-md-none d-block ">
                                <strong>What do the indicators mean:</strong>
                            </div>
                            <div class="col-md-12 col-6 p-0">
                                Accounts last 12 months
                            </div>
                        </div>
                        <div class="col-md-8 col-12 d-flex mb-md-0 mb-2">
                            <div class="col-6 p-0 d-md-none d-block ">
                                <strong>Description:</strong>
                            </div>
                            <div class="col-md-12 col-6 p-0">
                                How many accounts have you opened in the last 12 months.
                            </div>
                        </div>
                    </li>

                    <li class="">
                        <div class="col-md-4 col-12 d-flex mb-md-0 mb-2">
                            <div class="col-6 p-0 d-md-none d-block ">
                                <strong>What do the indicators mean:</strong>
                            </div>
                            <div class="col-md-12 col-6 p-0">
                                Youngest account
                            </div>
                        </div>
                        <div class="col-md-8 col-12 d-flex mb-md-0 mb-2">
                            <div class="col-6 p-0 d-md-none d-block ">
                                <strong>Description:</strong>
                            </div>
                            <div class="col-md-12 col-6 p-0">
                                How long has it been since your most recent account was opened.
                            </div>
                        </div>
                    </li>

                    <li class="">
                        <div class="col-md-4 col-12 d-flex mb-md-0 mb-2">
                            <div class="col-6 p-0 d-md-none d-block ">
                                <strong>What do the indicators mean:</strong>
                            </div>
                            <div class="col-md-12 col-6 p-0">
                                Unsecured debt
                            </div>
                        </div>
                        <div class="col-md-8 col-12 d-flex mb-md-0 mb-2">
                            <div class="col-6 p-0 d-md-none d-block ">
                                <strong>Description:</strong>
                            </div>
                            <div class="col-md-12 col-6 p-0">
                                Your total unsecured debt (so not a home or vehicle) as a contribution to your total debt.
                            </div>
                        </div>
                    </li>
                    
                </ul>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->


<!-- <div class="modal fade" id="findmore" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog ">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Debt Consolidation Indicator</h5>
                <button id="dButton-financial-wellness-×-2" type="button" class="close" data-bs-dismiss="modal" aria-label="Close" name="btnFinancialWellness_DebtConsolidationIndicatorClose">
                    <span id="dButton-financial-wellness-×-2-Span1" aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>
                    This indicator shows that it is very likely that DebtBusters can help you with a debt consolidation
                    product.<br /> Such a product can save you money on your monthly debt repayments.This will then free
                    up extra cash for living expenses.
                    
                </p>
            </div>
        </div>
    </div>
</div> -->

<ng-template #debtConsolidationIndicatorModal let-modal>
    <div id="findmore">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Debt Consolidation Indicator</h5>
                <button id="dButton-financial-wellness-×-2" type="button" class="close" (click)="modal.dismiss('Cross click')" name="btnFinancialWellness_DebtConsolidationIndicatorClose">
                    <span id="dButton-financial-wellness-×-2-Span1" aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>
                    This indicator shows that it is very likely that DebtBusters can help you with a debt consolidation
                    product.<br/> Such a product can save you money on your monthly debt repayments.This will then free
                    up extra cash for living expenses.
                     <!-- <br /><br />
                    Click on <a href="javascript:void(0)">View Solution</a> to find out exactly how much we could
                    possibly save you every month! -->

                </p>
                <div class="d-flex justify-content-center">
                    <button id="btn_debtConsolidationIndicator_infoPopUp_callMe_solutionsPage"  class="find-more btn popoverclose" name="debtConsolidationIndicator_infoPopUp_callMe_solutionsPage" (click)="callMeConfrimation()">Call me</button>
                </div>
               
            </div>
        </div>
    </div>
</ng-template>


<!-- Modal -->
<div class="modal fade" id="survyresult" data-backdrop="static" data-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">

    <div class="modal-dialog ">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title surveypopcaption" id="staticBackdropLabel">Survey Results</h5>
                <button id="dButton-financial-wellness-×-3" type="button" class="close" data-bs-dismiss="modal" aria-label="Close"  name="btnFinancialWellness_SurveyResultsClose">
                    <span id="dButton-financial-wellness-×-3-Span1" aria-hidden="true">&times;</span>
                </button>
            </div>
            
            <div class="modal-body text-dblack thankyputext" *ngIf="surveySalaryIndicator != null && surveySalaryIndicator.length >1 || 
                surveyAgeIndicator != null && surveyAgeIndicator.length >1"> 
Thank you for completing the survey! <br>

                <div *ngIf="surveyAgeIndicator.length >= 8" class="mt-3">
                    <h3 class="survy-caption text-dblack">Based on your age group:</h3>
                    <div *ngFor="let answerItem of surveyAgeIndicator;">
                        <ul class="survy-list text-dblack">
                            <li *ngIf="answerItem.questionNo==8"> {{answerItem.percentageAge}}% said their aspiration is: {{answerItem.answer}} </li>
                            <li *ngIf="answerItem.questionNo==10"> {{answerItem.percentageAge}}% said to earn money is to: {{answerItem.answer}} </li>
                            <li *ngIf="answerItem.questionNo==11"> {{answerItem.percentageAge}}% to save money is to: {{answerItem.answer}} </li> 
                        </ul>
                    </div>
                </div>

                <div  *ngIf="surveySalaryIndicator.length >= 2" class="mt-3">
                    <h3 class="survy-caption text-dblack">Based on your income group:</h3>
                    <div *ngFor="let answerItem of surveySalaryIndicator;">
                        <ul class="survy-list text-dblack">
                            <li *ngIf="answerItem.questionNo==2">{{answerItem.percentageSalary}}% said their income makes
                            them feel:
                            {{answerItem.answer}}</li>
                            <li *ngIf="answerItem.questionNo==9">{{answerItem.percentageSalary}}%
                            {{answerItem.answer=='Yes'?'Would':'Would not' }} be able to cover a R10,000
                            emergency expense today.</li>
                            <li *ngIf="answerItem.questionNo==5">{{answerItem.percentageSalary}}% spent more than R5,000 in
                            the last 6 months on:
                            {{answerItem.answer.replace('Yes.','').replace('No.','') }}</li>
                        </ul>
                    </div>
                </div>
                <!--Watch this space to see how our other clients responded to these same questions. <br>-->

                <!-- <h3 class="survy-caption">Your selection compared to our other clients:</h3>

                <div *ngFor="let answerItem of surveyIndicator;">
                <ul class="survy-list">
                <li *ngIf="answerItem.questionNo==3">You said {{answerItem.answer }}.
                {{ answerItem.percentageOtherAll }}% of clients who said the same, also agreed that
                they
                {{question9=='Yes'?'Would':'Would not' }} be able to cover a R10,000 emergency expense today.</li>
                <li *ngIf="answerItem.questionNo==4">You said {{answerItem.answer}}.
                {{answerItem.percentageOtherAll}}% of clients
                who said the same, also agreed that, To earn money is to  {{question10}}. </li>

                <li *ngIf="answerItem.questionNo==6">{{answerItem.percentageOtherAll}}% said
                {{answerItem.answer}}.</li>
                <li *ngIf="answerItem.questionNo==7">{{answerItem.percentageOtherAll}}% said
                {{answerItem.answer}}.</li>
                </ul>
                </div>  -->
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title text-dblack">Callback request</h4>
                <button id="dButton-financial-wellness-×-4" type="button" class="close" aria-label="Close" data-bs-dismiss="modal">
                    <span id="dButton-financial-wellness-×-4-Span1" aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="callbackErrorMsg!=''" class="alert alert-danger" role="alert">
                    {{callbackErrorMsg}}
                </div>                
                <div *ngIf="callbackSuccessMsg!=''" class="alert alert-success" role="alert">
                    {{callbackSuccessMsg}}
                </div>
                <div  class="txthd">Do you want a Financial Assessor to call you now?</div>
            </div>
            <div class="modal-footer">
                <button id="dButton-financial-wellness-No" type="button" class="btn-border btnyes" data-bs-dismiss="modal" name="btnFinancialWellness_CallbackModel_No">No</button>
                <button id="dButton-financial-wellness-Yes" type="button" class="btn btn-primary btnyes" (click)="callbackRequest()" name="btnFinancialWellness_CallbackModel_Yes">Yes</button>
            </div>
        </div>
    </div>
</div>

<!-- Omni lead call popup -->
<ng-template #callbackConfirmationModel let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Free callback request</h4>
      <button type="button" id="dButton-financial-wellness-×-4" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span id="dButton-financial-wellness-×-4-Span1" aria-hidden="true"><svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><path id="noheadercl" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></defs><use fill="#fff" fill-rule="nonzero" xlink:href="#noheadercl"/></svg></span>
      </button>
    </div>
    <div class="modal-body insuremcealinks">
      <div *ngIf="callbackErrorMsg!=''" class="alert alert-success" role="alert">
        {{callbackErrorMsg}}
      </div>
      <div *ngIf="callbackSuccessMsg!=''" class="alert alert-success" role="alert">
        {{callbackSuccessMsg}}
      </div>
      <!-- <div class="txthd" *ngIf="callbackErrorMsg==''">Do you want a Financial Assessor to call you now?</div> -->
    </div>
    <!-- <div class="modal-footer" *ngIf="callbackErrorMsg==''">
      <button type="button" id="btn-ModalDismissNo-Insurance" class="btn-border" (click)="modal.dismiss('No')">No</button>
      <button type="button" class="btn btn-primary" id="btn-CallBackCampaign-Insurance" (click)="callback(campaignSourceToModel)">Yes</button>
    </div> -->
  </ng-template>


<ng-template #potentialCustomers let-modal>

    <div class="modal-dialog p-0 m-0">
        <div class="modal-content">
            <div class="modal-header">
                <!-- text-dblack -->
                <h5 class="modal-title">We may be able to help you</h5>

                <button type="button" id="btn-SurveyResultsClose-FinancialWellness" class="close" (click)="modal.dismiss('Cross click')"  name="btnFinancialWellness_SurveyResultsClose">
                    <span id="btn-SurveyResultsClose-FinancialWellness-Span1" aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="callFinancialWellnessbackErrorMsg!=''" class="alert alert-danger position-inherit" role="alert">
                    {{callFinancialWellnessbackErrorMsg}}
                  </div>
                  <div *ngIf="callBackFinancialWellnessSuccessMsg!=''" class="alert alert-success position-inherit" role="alert">
                    {{callBackFinancialWellnessSuccessMsg}}
                  </div>
                  <div  *ngIf="showSection">
                Based on your answers to the Financial Wellness Survey, we may have a solution for you. 
                    Click "Yes" for a free callback from one of our Financial Assessors.
                    </div>
            </div>
            <div class="modal-footer" *ngIf="showSection">
                <button type="button" id="btn-CallbackModel_No-FinancialWellness" class="btn-border btnyes"  (click)="modal.dismiss('Cross click')" name="btnFinancialWellness_CallbackModel_No">No</button>
         
                <button id="btn-CallbackModel_Yes-FinancialWellness" class="btn btn-primary btnyes" (click)="callbackRequestForPotentialUsers()"
                                   name="btnFinancialWellness_CallbackModel_Yes">Yes</button>
            
            </div>
        </div>
    </div>

</ng-template>

<ng-template #directCallbackModel let-modal>
    <div class="old-design">
      <div class="modal-header">
        <h4 class="modal-title text-dblack">Callback request</h4>
        <button type="button" id="btn-ModalDismiss-Dashboard" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span id="btn-ModalDismiss-Dashboard-Span1" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="autoCallbackErrorMsg!=''" class="alert alert-danger" role="alert">
          {{autoCallbackErrorMsg}}
        </div>
        <div *ngIf="autoCallbackSuccessMsg!=''" class="alert alert-success" role="alert">
          {{autoCallbackSuccessMsg}}
        </div>
      </div>
    </div>
  </ng-template>




