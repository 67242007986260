import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dc-timeline-popup',
  templateUrl: './dc-timeline-popup.component.html',
  styleUrls: ['./dc-timeline-popup.component.css']
})
export class DcTimelinePopupComponent implements OnInit {


  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
  }
  closePopup(){
    this.modalService.dismissAll()
  }
  
}
