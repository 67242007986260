import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header.component';
import { FooterComponent } from './footer.component';
import { ContactComponent } from './contact.component';
import { CallMeBackComponent } from './callmeback.component';
import { RouterModule } from '@angular/router';
import { NumericFieldDirective } from '../helpers/directive/numeric-field.directive'; 
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { PasswordStrengthBar } from '../helpers/components/password-strength-bar';
import { AppInsightsService } from '../services/appinsights.service';
import { PasswordStrengthCheck } from '../helpers/components/password-strength-check';
import { FormatTimePipe } from '../helpers/pipe/format-time.pipe';
import { ReferralTermsPopupComponent } from '../referral-terms-popup/referral-terms-popup.component';
import { DcTimelinePopupComponent } from '../dc-timeline/dc-timeline-popup/dc-timeline-popup.component';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule],
    declarations: [CallMeBackComponent, HeaderComponent, FooterComponent, ContactComponent,
        NumericFieldDirective, PasswordStrengthBar,PasswordStrengthCheck, FormatTimePipe,ReferralTermsPopupComponent,DcTimelinePopupComponent],
    exports: [CallMeBackComponent, HeaderComponent, FooterComponent, ContactComponent,
        CommonModule, FormsModule, ReactiveFormsModule, NumericFieldDirective, PasswordStrengthBar,PasswordStrengthCheck, FormatTimePipe,
    ReferralTermsPopupComponent,DcTimelinePopupComponent]


})
export class SharedModule {
    appInsights: ApplicationInsights;
    constructor(private cookieService: CookieService, private appInsightsService: AppInsightsService) {
        this.appInsights = appInsightsService.appInsightsObj();
    }

    sendAppInsights(callback: boolean) {
        this.appInsights.trackEvent({
            name: 'RegisterInfo',
            properties: {
                Type: 'RegisterLog',
                Name: 'IsAskSecurityQuestionWithSlotB',
                Value: environment.askSecurityQuestion,
                Page: window.location.pathname,
                UserId: this.cookieService.get('userId'),
                SubmissionId: this.cookieService.get('submissionId'),
                IsAskSecurityQuestionWithSlotB: environment.askSecurityQuestion
            }
        });
    }
}
