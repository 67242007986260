<div class="center-screen">
  <div class="header-container bg-blue position-relative">
    <div class="position-absolute q-svg">
      <svg xmlns="http://www.w3.org/2000/svg" width="77" height="135" viewBox="0 0 77 135" fill="none">
        <path d="M76.9766 55.3135C76.9766 63.2725 76.0244 70.5723 74.1201 77.2129C72.2646 83.8047 69.3838 89.5176 65.4775 94.3516C61.5713 99.1855 56.6152 102.97 50.6094 105.704L76.3906 134.488H47.3135L27.6846 110.465C27.3428 110.465 27.0498 110.465 26.8057 110.465C26.5615 110.465 26.293 110.465 26 110.465C17.2598 110.465 9.69141 109.171 3.29492 106.583C-3.10156 103.946 -8.39941 100.187 -12.5986 95.3037C-16.749 90.4209 -19.8496 84.5859 -21.9004 77.7988C-23.9512 71.0117 -24.9766 63.4678 -24.9766 55.167C-24.9766 44.083 -23.1699 34.4395 -19.5566 26.2363C-15.8945 17.9844 -10.2793 11.5879 -2.71094 7.04688C4.85742 2.50586 14.4766 0.235352 26.1465 0.235352C37.7676 0.235352 47.3135 2.50586 54.7842 7.04688C62.3037 11.5879 67.8701 17.9844 71.4834 26.2363C75.1455 34.4883 76.9766 44.1807 76.9766 55.3135ZM-1.17285 55.3135C-1.17285 62.7842 -0.245117 69.2295 1.61035 74.6494C3.51465 80.0205 6.46875 84.1709 10.4727 87.1006C14.4766 89.9814 19.6523 91.4219 26 91.4219C32.4453 91.4219 37.6699 89.9814 41.6738 87.1006C45.6777 84.1709 48.583 80.0205 50.3896 74.6494C52.2451 69.2295 53.1729 62.7842 53.1729 55.3135C53.1729 44.083 51.0732 35.2451 46.874 28.7998C42.6748 22.3545 35.7656 19.1318 26.1465 19.1318C19.75 19.1318 14.5254 20.5967 10.4727 23.5264C6.46875 26.4072 3.51465 30.5576 1.61035 35.9775C-0.245117 41.3486 -1.17285 47.7939 -1.17285 55.3135Z" fill="white" fill-opacity="0.2"/>
        </svg>
    </div>
    <div class="title d-flex justify-content-around">
      <a id="">
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
          <path
            d="M13.7128 15C14.628 13.058 17.0974 11.6667 20.0001 11.6667C23.682 11.6667 26.6668 13.9052 26.6668 16.6667C26.6668 18.9991 24.5374 20.9585 21.6571 21.511C20.7531 21.6844 20.0001 22.4129 20.0001 23.3333M20 28.3333H20.0167M35 20C35 28.2843 28.2843 35 20 35C11.7157 35 5 28.2843 5 20C5 11.7157 11.7157 5 20 5C28.2843 5 35 11.7157 35 20Z"
            stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </a>
      <div class="help-txt text-center text-white">Help</div>
      <a  class="overlay" id="btn_dcTimeline_stageInfoPopUp_close_progress" (click)="closePopup()">
        <svg  class="backlay" role="button" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M24 8L8 24" stroke="white" stroke-linecap="square" stroke-linejoin="round" />
          <path d="M8 8L24 24" stroke="white" stroke-linecap="square" stroke-linejoin="round" />
        </svg>
      </a>
    </div>
    <div class="sub-title text-center py-3 px-md-3">
      <div class="">{{needhelpTitle}}</div>
    </div>
  </div>

  <div class="content d-flex justify-content-center mt-0 px-3 position-relative">
    <div class="position-absolute a-svg">
      <svg width="78" height="108" viewBox="0 0 78 108" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M53.2461 108L45.4824 82.5117H6.44434L-1.31934 108H-25.7822L12.0107 0.480469H39.7695L77.709 108H53.2461ZM40.0625 63.4688L32.2988 38.5664C31.8105 36.9062 31.1514 34.7822 30.3213 32.1943C29.54 29.5576 28.7344 26.8965 27.9043 24.2109C27.123 21.4766 26.4883 19.1084 26 17.1064C25.5117 19.1084 24.8281 21.5986 23.9492 24.5771C23.1191 27.5068 22.3135 30.29 21.5322 32.9268C20.751 35.5635 20.1895 37.4434 19.8477 38.5664L12.1572 63.4688H40.0625Z"
          fill="#BEBEBE" fill-opacity="0.3" />
      </svg>
    </div>
   
    <div class="scrollable-content px-5" [innerHTML]="needhelpContent"></div>
  </div>
</div>