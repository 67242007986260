import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn : 'root'
})

export class DcProgressService{

    env = environment;
    constructor(private http:HttpClient) {}

    fetchDcTimelineData(){ 
        return this.http.post(this.env.webApiUrl + 'api/DCTimeline/GetDCTimeline', null);
      }

}