import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FinancialWellnessService } from '../services/financial-wellness.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CommunicationService } from '../services/communication.service';
import { DashboardService } from '../services/dashboard.service';
import { UserService } from '../services/user.service';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import { environment } from '../../environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';
import { Chart } from 'node_modules/chart.js';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-financial-wellness',
  templateUrl: './financial-wellness.component.html',
  host: { 'class': 'd-flex flex-column h-100' },
  styleUrls: ['./financial-wellness.component.css']

})
export class FinancialWellnessComponent implements OnInit {
  env = environment;
  userData;
  @ViewChild('gaugeValue', { static: false }) gaugeValue: ElementRef;
  @ViewChild('gaugeValueFinancial', { static: false }) gaugeValueFinancial: ElementRef;
  @ViewChild('potentialCustomers', { static: false }) potentialCustomers: ElementRef;
  @ViewChild('debtConsolidationIndicatorModal', { static: false }) debtConsolidationIndicatorModal: ElementRef;
  @ViewChild('callbackConfirmationModel', { static: false }) callbackConfirmationModel: ElementRef;
  

  @ViewChild('directCallbackModel', { static: false }) directCallbackModel: ElementRef;
  
  modalOptions: NgbModalOptions;
  constructor(
    private financialWellnessService: FinancialWellnessService,
    private communicationService: CommunicationService,
    private dashboardService: DashboardService,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private cookieService: CookieService,
    private oauthService: OAuthService,
    private modalService: NgbModal,
  ) {
    this.modalOptions = {
      backdrop: 'static',
      // backdropClass: 'customBackdrop',
      keyboard: false
    };
   }
  wellnessProgress = 66;
  progressBarStatus = 'Please complete your fun survey.';
  degree = 0;
  surveyAnswered: any = {};
  surveyIndicator: any = {};
  surveyStatus = '';

  isHidden = true;
  question9 = '';
  question10 = '';

  callbackErrorMsg = '';
  callFinancialWellnessbackErrorMsg = '';
  callBackFinancialWellnessSuccessMsg = '';
  creditHealthInfo = {};

  dbC_DRS = 0;
  grossIncome = 0;
  monthlyInstallment = 0;
  dbC_Eligibility = 0;

  isSolution = false;
  wellnessDegree = 0;
  IsHidden = true;
  wellnessProgressSolution = false;
  debtConsolidationPercentage = 0;
  debtConsolidationdegree = 0;
  debtConsolidationStatus = '';

  creditScoreGroup = 0;
  salaryTowardDebtGroup = 0;
  openAccountsGroup = 0;
  arrearsGroup = 0;
  paidOffGroup = 0;
  account12MonthsGroup = 0;
  ageYoungestAccountGroup = 0;
  unsecuredToDebtGroup = 0;
  radarPercent = 0;
  radarStatus = '';
  type = 3;

  surveySalaryIndicator: any = {};
  surveyAgeIndicator: any = {};
  potentialUserAsPerSurveyQNA: boolean = false;
  callbackSuccessMsg : string = '';
  showSection: boolean = true;
  surveyQuestions: boolean = null;
  userAttemptedTheSurveyInTheSession: boolean = null;
  autoCallbackSuccessMsg = '';
  autoCallbackErrorMsg = '';
  windowScrolled = false;

  ngOnInit() {

    this.type = parseInt(this.cookieService.get('uType'));
    console.log(this.type, 'this is the user type');
    if (this.type === 1) {
      window.location.href = '/portal/dashboard';
      return;
    }

    if(this.type === 2){
      console.log('hiding debt consolidation');
      $('#debt-consolidation-indicator').attr('style','display:none !important');
      $('#debt-solution').attr('style','display:none !important');
      this.wellnessProgress = 66;
      this.progressBarStatus = 'Please complete your fun survey.';
      $('#solution').hide();
    }
  
    this.IsHidden = true;
    this.userData = this.oauthService.getIdentityClaims();

    const date = new Date();
    const hour = date.getUTCHours() + 2;
    const day = date.getDay();
    const minutes = date.getUTCMinutes();

    if(this.type === 3){
      if (day === 1 || day === 2 || day === 3 || day === 4) {
        if (hour === 7 && minutes >= 30) {
          this.createChat();
        }
        if (hour >= 8 && hour <= 18) {
          this.createChat();
        }
      }
      if (day === 5) {
        if (hour === 7 && minutes >= 30) {
          this.createChat();
        }
        if (hour >= 8 && hour <= 17) {
          this.createChat();
        }
      }
    }

    this.getSurveyAnswered(() => {
      this.GetRadarChart();
      if(this.type === 3){
        this.getDebtConsolidation();
      }
    });

    //for desktop chart
   
    // for mobile chart
    
  }

  
GetRadarChart(){
  this.dashboardService.radarValues().subscribe((data: any) => {
    this.BindRadarChart(data);
  });
}

BindRadarChart(data){
    console.log("radar values");
    console.log(data);
    this.creditScoreGroup = this.RadarValuesCalc(data.creditScoreGroup);
    this.salaryTowardDebtGroup = this.RadarValuesCalc(data.salaryTowardDebtGroup);
    this.openAccountsGroup = this.RadarValuesCalc(data.openAccountsGroup);
    this.arrearsGroup = this.RadarValuesCalc(data.arrearsGroup);
    this.paidOffGroup = this.RadarValuesCalc(data.paidOffGroup);
    this.account12MonthsGroup =  this.RadarValuesCalc(data.account12MonthsGroup);
    this.ageYoungestAccountGroup = this.RadarValuesCalc(data.ageYoungestAccountGroup);
    this.unsecuredToDebtGroup = this.RadarValuesCalc(data.unsecuredDebtGroup);
    this.radarPercent = data.scorePercent;
    if(this.radarPercent >= 30 && this.radarPercent <= 52){
      this.radarStatus = '<span class="status-orange">Let&#8217;s <b class="fboldr">Talk</b></span>';
    }
    if(this.radarPercent >= 53 && this.radarPercent <= 61){
      this.radarStatus = '<span class="status-orange">Needs <b class="fboldr">Some Work</b></span>';
    }
    if(this.radarPercent >= 62 && this.radarPercent <= 69){
      this.radarStatus = '<span class="status-green"><b  class="fboldr">Healthy</b></span>';
    }
    if(this.radarPercent >= 70 && this.radarPercent <= 77){
      this.radarStatus = '<span class="status-green"><b  class="fboldr">Great</b> Health</span>';
    }
    if(this.radarPercent >= 78){
      this.radarStatus = '<span class="status-green"><b  class="fboldr">Excellent</b> Health</span>';
    }


    var myRadarChart = new Chart('myChart', { 
    type: 'radar',
    //scaleSteps:3,
    data: {
      labels: [
        ["Credit Score"],
        ["Salary" , "toward", "debt"],
        ["Open" , 'Accounts'],
        ["Arrears" , 'Group'],
         'Paid-off',
        ["Accounts" , '12 Months'],
        ["Age" , 'Youngest' , ' Account'],
        ["Unsecured", 'to Total' , 'debt']
      ],

      datasets: [{
        //label: 'jbjh',
        backgroundColor: "rgba(156,208,101,0.45)",
        borderColor: "#8DC656",
        pointBorderColor: "#2b6817",
        pointBackgroundColor: "#2b6817",
        pointRadius:2,
        fill: true,
        borderWidth: 2,
        data: [this.creditScoreGroup, this.salaryTowardDebtGroup, this.openAccountsGroup, this.arrearsGroup, this.paidOffGroup, this.account12MonthsGroup, this.ageYoungestAccountGroup, this.unsecuredToDebtGroup]
       
      }]
      
    },
    options: {
      
      layout: {
        padding: {
            left:0,
            right: 0,
            top: 0,
            bottom: 0
        }
    },
      tooltips: {
        enabled: false
      },
      responsive: true,
      //maintainAspectRatio: false,
      //aspectRatio:100,
      legend: {
        display: false,
      },

      scale: {
        angleLines: {
          display: false
        },
        pointLabels: { 
          fontSize: 14 , 
          padding:5, 
          fontColor:"transparent",
        },
        axis: {
          labelAngle: 0,
        },
        ticks: {
          
          //suggestedMin:0,
          //suggestedMax: 5,
          display: false,
          beginAtZero: true,
          max:20,
          min: 1,
          stepSize:2
        },
        gridLines: {
          color: [ '#82c157','#82c157', '#9cd065','#9cd065', '#C3E3A2','#C3E3A2', '#e6f3d8','#e6f3d8', '#ffffff' ,'#ff8726'],
          lineWidth: ['40' ,'40' , '40' , '40' , '40' ,'40' ,'40' , '40' , '35' , '2' ],
        },
        callback: function(value, index, values) {
          if (value % 10 === 0) {
            return value;
          } else {
            return ' ';
          }
        }
        //responsive: {
          //'width < 700': {
            //gridLines: {
              //lineWidth: '5',
              //},
            //pointLabels: { fontSize: 10 , padding:5},
          //}
        //}
      },
      elements: {
        "line": { "tension": 0, "borderWidth": 3 }
      },
    },
    
  });

    var myRadarChart = new Chart('chartMobile', {
    
    type: 'radar',
    //scaleSteps:3,
    data: {
      labels: [
        ["Credit" , "Score"],
        ["Salary" , "toward", "debt"],
        ["Open" , 'Accounts'],
        ["Arrears" , 'Group'],
        'Paid-off',
        ["Accounts" , '12 Months'],
        ["Age" , 'Youngest' , ' Account'],
        ["Unsecured", 'to Total' , 'debt']
      ],

      datasets: [{
        //label: 'jbjh',
        backgroundColor: "rgba(156,208,101,0.45)",
        borderColor: "#8DC656",
        pointBorderColor: "#2b6817",
        pointBackgroundColor: "#2b6817",
        pointRadius:2,
        fill: true,
        borderWidth: 2,
        data: [this.creditScoreGroup, this.salaryTowardDebtGroup, this.openAccountsGroup, this.arrearsGroup, this.paidOffGroup, this.account12MonthsGroup, this.ageYoungestAccountGroup, this.unsecuredToDebtGroup]
       
      }]
    },
    options: {
      layout: {
        padding: {
            left:0,
            right: 0,
            top: 0,
            bottom: 0
        }
    },
      tooltips: {
        enabled: false
      },
      responsive: true,
      //maintainAspectRatio: false,
      //aspectRatio:100,
      legend: {
        display: false,
      },
      scale: {
        angleLines: {
          display: false
        },
        pointLabels: { 
          fontSize: 14 , 
          padding:5, 
          fontColor:"transparent",
        },
        axis: {
          labelAngle: -100
        },
        ticks: {
          //suggestedMin:0,
          //suggestedMax: 5,
          display: false,
          beginAtZero: true,
          max:20,
          min: 1,
          stepSize:2
        },
        gridLines: {
          color: [ '#82c157','#82c157', '#9cd065','#9cd065', '#C3E3A2','#C3E3A2', '#e6f3d8','#e6f3d8', '#ffffff' ,'#ff8726'],
          lineWidth: ['10' ,'10' , '10' , '10' , '10' ,'10' ,'10' , '10' , '15' , '2' ],
        },
        //responsive: {
          //'width < 700': {
            //gridLines: {
              //lineWidth: '5',
              //},
            //pointLabels: { fontSize: 10 , padding:5},
          //}
        //}
      },
      elements: {
        "line": { "tension": 0, "borderWidth": 3 }
      },
    },
  });
}


  getDebtConsolidation() {
       this.userService.GetDbcUserSolutionInfo({ IsSolution: 'yes' }).subscribe((data: any) => {
      this.BindDebtConsolidation(data);
    });
  }

  BindDebtConsolidation(data) {  
      if (!data.isSolution) {
        $('#solution').hide();
       this.getGetDbcUserEligibility();
      } else {

        this.dbC_DRS = data.dbC_DRS;
        this.dbC_Eligibility = data.dbC_Eligibility;
        this.grossIncome = data.grossIncome;
        this.monthlyInstallment = data.monthlyInstallment;

        $('#debt-solution').show();
        $('#solution').show();
        this.isSolution = true;
        // this.buildProgressBar();
        this.debtConsolidationPercentage = data.dbC_Eligibility;
        this.debtConsolidationdegree = (this.debtConsolidationPercentage - 50) * 2.7;
        this.getDebtConsolidationStatus(data.dbC_Eligibility);
        this.gaugeValue.nativeElement.style.setProperty('--gauge-value', this.debtConsolidationPercentage);
      }
  }

  getGetDbcUserEligibility(){

    this.isSolution = false;
        this.userService.GetDbcUserEligibility().subscribe((data: any) => {
          console.log(data);
          if(data!=null){
          this.debtConsolidationPercentage = data.dbC_Eligibility;
          console.log("thiosi", this.debtConsolidationPercentage);
          this.debtConsolidationdegree = (this.debtConsolidationPercentage - 50) * 2.7;
          this.getDebtConsolidationStatus(data.dbC_Eligibility);
          this.gaugeValue.nativeElement.style.setProperty('--gauge-value', this.debtConsolidationPercentage);
          }
        });

  }


  getDebtConsolidationStatus(percentage) {
    if (percentage >= 0 && percentage <= 56) {
      this.debtConsolidationStatus = 'Possible';
    } else if (percentage >= 57 && percentage <= 64) {
      this.debtConsolidationStatus = 'Likely';
    } else if (percentage >= 65 && percentage <= 77) {
      this.debtConsolidationStatus = 'Very possible';
    } else if (percentage >= 78 && percentage <= 89) {
      this.debtConsolidationStatus = 'Very likely';
    } else if (percentage >= 90 && percentage <= 100) {
      this.debtConsolidationStatus = 'Extremely likely';
    }
  }


  // IsHidden= true;
  onSelect() {

    this.userService.GetDbcUserSolutionInfo({ IsSolution: 'no' }).subscribe((data: any) => {
      this.dbC_DRS = data.dbC_DRS;
      this.dbC_Eligibility = data.dbC_Eligibility;
      this.grossIncome = data.grossIncome;
      this.monthlyInstallment = data.monthlyInstallment;

      $('#solution').show();
      this.isSolution = true;
      this.buildProgressBar();
    });
  }

  buildProgressBar() {
    this.wellnessProgress += 33;
    if (this.wellnessProgress === 99) {
      this.wellnessProgress = 100;
    }
    this.wellnessDegree = (this.wellnessProgress - 50) * 2.7;
    if (!this.wellnessProgressSolution) {
      this.progressBarStatus = 'Please complete your fun survey.';
    }
  }
  getSurveyAnswered(callback) {
   
    this.financialWellnessService.getSurveyAnswered().subscribe((data: any) => {
      this.BindSurveyAnswered(callback,data);

    }, (err: HttpErrorResponse) => {

    });
    
  }

  BindSurveyAnswered(callback,data) {
 
      let surveyCompleted = localStorage.getItem("SurveySuccessfullyCompeleted");
      

      this.surveyAnswered = data;
      this.degree = (this.surveyAnswered.surveyPercentage - 50) * 2.7;
      console.log(this.type);
      if(callback){
        callback();
      }
      var isSurvayAnswered = false;

      
      if (this.surveyAnswered.answered === this.surveyAnswered.totalQuestions) {
        this.potentialUserAsPerSurveyQNA;
        this.wellnessProgressSolution = true;
        this.getSurveyStatus(this.surveyAnswered.surveyPercentage);  
        this.gaugeValueFinancial.nativeElement.style.setProperty('--gauge-value', this.surveyAnswered.surveyPercentage);
        isSurvayAnswered = true;
        if(surveyCompleted)
        {
          this.userAttemptedTheSurveyInTheSession = true;
          this.getPotentialDebtCounsellingUserCheck();
          localStorage.removeItem("SurveySuccessfullyCompeleted");
        }
        else
        {
          this.getPotentialDebtCounsellingUserCheck();
        }
        
        if(this.type === 3){
          this.wellnessProgress = 66;
          this.progressBarStatus = 'Please view your solution';
        }
        else{
          this.wellnessProgress = 100;
          this.progressBarStatus = '';
        }        
        
      }

      if (this.type === 3 && this.wellnessProgress != 100) {
        this.wellnessProgress += 33;
      }
      if (this.wellnessProgress === 99 && this.type === 3) {
        if(isSurvayAnswered == true){
          this.wellnessProgress = 100;
        } else {
          this.wellnessProgress = 66;
        }
        
      }
      this.wellnessDegree = (this.wellnessProgress - 50) * 2.7;
    
  }

  getSurveyStatus(surveyPercentage) {
    if (surveyPercentage >= 25 && surveyPercentage <= 47) {
      this.surveyStatus = 'Risky behaviour';
    } else if (surveyPercentage >= 48 && surveyPercentage <= 66) {
      this.surveyStatus = 'Some risky behaviour';
    } else if (surveyPercentage >= 67 && surveyPercentage <= 81) {
      this.surveyStatus = 'Responsible approach';
    } else if (surveyPercentage >= 82 && surveyPercentage <= 92) {
      this.surveyStatus = 'Great attitude';
    } else if (surveyPercentage >= 93 && surveyPercentage <= 100) {
      this.surveyStatus = 'Best attitude';
    }
  }
 
  callbackRequest() {
    const param = { campaignSource: '' };
    this.communicationService.leadCallback(param).subscribe((data: any) => {
      this.callbackSuccessMsg = 'Our Financial Assessor will get in touch with you';
      $(".txthd").hide();
      $(".modal-footer").hide();
    }, (err: HttpErrorResponse) => {
      this.callbackErrorMsg = 'Something went wrong. Please try again later';
    });
  }

  directCallbackPopup(campaignSource){
      this.autoCallbackSuccessMsg = '';
      this.autoCallbackErrorMsg = '';
      const param = { campaignSource: '' };
      this.communicationService.leadCallback(param).subscribe((data: any) => {
        this.autoCallbackSuccessMsg = 'Our Financial Assessor will get in touch with you';
        this.modalService.open(this.directCallbackModel, this.modalOptions);
      }, (err: HttpErrorResponse) => {
        this.autoCallbackErrorMsg = 'Something went wrong. Please try again later'
        this.modalService.open(this.directCallbackModel, this.modalOptions);
      });
      
  }

  getSurveyIndicator() { 
    // this.sendGAEvent(this.type,'FinancialBehaviourIndicator_FindOutMore');
    // this.financialWellnessService.getSurveyIndicator().subscribe((data: any) => {
    //    this.surveyIndicator = data;
    //    this.question9 = data.find(k => k.questionNo === 9).answer;
    //    this.question10 = data.find(k => k.questionNo === 10).answer;
    //  }, (err: HttpErrorResponse) => {

    //  });

    this.financialWellnessService.getComputeResultForSalaryAndAgeSegment().subscribe((data: any) => {
      if(data)
       {
            this.surveyAgeIndicator = data.siForAgeSegment;
            this.surveySalaryIndicator = data.siForSalarySegment;
            if(this.surveySalaryIndicator != null && this.surveyAgeIndicator != null)
            {
              this.question9 = data.siForSalarySegment.find(k => k.questionNo === 9)?.answer;
              this.question10 = data.siForAgeSegment.find(k => k.questionNo === 10)?.answer;  
            }  
      }
    }, (err: HttpErrorResponse) => {

    });

  }





  sendGAEvent(type, eventName) {
    console.log(type);
    window['dataLayer'].push({
      'event': eventName, 
      'userType': type,
    }); 
  }

  createChat() {
    if (this.cookieService.get('chatEnabled') == 'true') {
      (window as any).fcWidget.init({
        token: this.env.freshChatToken,
        host: this.env.freshChatHost,
        tags: ['DBclient'],
        externalId: this.userData.name,     // user’s id unique to your system
        firstName: this.userData.given_name,              // user’s first name
        lastName: this.userData.family_name
      });
    } else {
      setTimeout(() => {
        console.log('calling set timeout'); 
        this.cookieService.set('chatEnabled', 'true', null, '/', undefined, true);
        (window as any).fcWidget.init({
          token: this.env.freshChatToken,
          host: this.env.freshChatHost,
          tags: ['DBclient'],
          externalId: this.userData.name,     // user’s id unique to your system
          firstName: this.userData.given_name,              // user’s first name
          lastName: this.userData.family_name
        });
      }, 60 * 1000);
    }
  }



  getPotentialDebtCounsellingUserCheck(){
      this.financialWellnessService.getPotentialDebtCounsellingUserCheck().subscribe((data: any) => {
        if(data){
           sessionStorage.setItem('potentialUserAsPerSurveyQNA', JSON.stringify(data));
           this.potentialUserAsPerSurveyQNA = data;
           if(this.userAttemptedTheSurveyInTheSession && data)
           {
                this.showCallMeTOPotentialCustomers();
           }
        }
      });
  }

  showCallMeTOPotentialCustomers(){
    this.modalService.open(this.potentialCustomers, this.modalOptions );
  }


  RadarValuesCalc(score){
   var inverse = 6 - score
   var radarplot = inverse * 4;
   return radarplot;
  }

  callbackRequestForPotentialUsers(){

    const param = { campaignSource: 'FinancialBehavior_CallMe', referrer: 'DBClient', WebSource: 'Survey' };
    this.communicationService.leadCallback(param).subscribe((data: any) => {
      this.callBackFinancialWellnessSuccessMsg = 'Our Financial Assessor will get in touch with you';

      this.showSection = false;
    }, (err: HttpErrorResponse) => {
      this.callFinancialWellnessbackErrorMsg = 'Something went wrong. Please try again later';
    });
  }

  debtConsolidationIndicatorPopup(){
    this.modalService.open(this.debtConsolidationIndicatorModal, this.modalOptions  );
  }

  //Omni lead API call
  callMeConfrimation(){
    this.modalService.dismissAll()
    const param = { campaignSource: 'btn_debtConsolidationIndicator_infoPopUp_callMe_solutionsPage', referrer: 'DBClient', WebSource: 'Callback' };
    this.communicationService.leadCallback(param).subscribe((data: any) => {
      this.callbackSuccessMsg = 'Our Financial Assessor will get in touch with you';
      this.modalService.open(this.callbackConfirmationModel, this.modalOptions);
      // this.showSection = false;
    }, (err: HttpErrorResponse) => {
      this.callFinancialWellnessbackErrorMsg = 'Something went wrong. Please try again later';
      this.modalService.open(this.callbackConfirmationModel, this.modalOptions);
    });
  }

}
