import { AuthConfig } from 'angular-oauth2-oidc';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  webApiUrl: 'https://app-dbcore-api-dev-001.azurewebsites.net/',
  sessionTimeOut: 60 * 5,
  appInsights: {
    isEnable: true,
    instrumentationKey: '8df56b39-6f79-46b6-bc45-497900e8662c'
  },
  askSecurityQuestion: true,
  freshChatToken: '65208bb8-49c6-4970-9734-27423157470f',
  freshChatTokenForUserType1: 'a1792f92-3210-43d4-9549-7d7876609fd9',
  freshChatHost: 'https://wchat.freshchat.com',
  azureAdConfig: {
    b2cDomain: 'b2clogin.com',
    discoveryDocumentConfig: {
      url: 'https://debtbustersdev.b2clogin.com/tfp/debtbustersdev.onmicrosoft.com/B2C_1_scs/v2.0/.well-known/openid-configuration'
    },
    authConfig: {
      redirectUri: window.location.origin + '/portal/dashboard',
      postLogoutRedirectUri: window.location.origin + '/login',
      responseType: 'token id_token',
      issuer: 'https://debtbustersdev.b2clogin.com/tfp/c5f1c54e-10b7-4382-b2af-5d28b486b73c/b2c_1_scs/v2.0/',
      strictDiscoveryDocumentValidation: false,
      tokenEndpoint: 'https://debtbustersdev.b2clogin.com/debtbustersdev.onmicrosoft.com/b2c_1_scs/oauth2/v2.0/token',
      loginUrl: 'https://debtbustersdev.b2clogin.com/debtbustersdev.onmicrosoft.com/b2c_1_scs/oauth2/v2.0/token',
      clientId: '9ff8eb46-f71f-4157-98a4-82989b9e919f',
      scope: 'openid profile https://debtbustersdev.onmicrosoft.com/scs/user_impersonation',
      skipIssuerCheck: true,
      clearHashAfterLogin: true,
      oidc: true,
    }
  },
  enableServiceWorker: true,
  firebase: { 
    apiKey: "AIzaSyAm2KI_0GVycfkIXI7ABxnWEvzaVrE6U6Y",
    authDomain: "debtbusters-dev.firebaseapp.com",
    projectId: "debtbusters-dev",
    storageBucket: "debtbusters-dev.appspot.com",
    messagingSenderId: "598687652227",
    appId: "1:598687652227:web:1f95ee65a8fee49c9bfeb6"
  },
  trackingUrl:"https://devtrc.digitechlab.co.za/lib/minified-js/tracking-lib.js",
  trackingId:"002",
  sapiBaseUrl:"https://app-sapi-ui-dev-01.azurewebsites.net",
  clienteleBaseUrl:"https://cbc-uat.cbcrewards.co.za/JustMoney/ms",
  clienteleAccountParamValue: "JST_App"
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
