<div class="center-screen">
  <div class="header-container bg-blue">
  <div class="title d-flex justify-content-around">
    <svg role="button" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.9999 36.6666C10.7949 36.6666 3.33325 29.2049 3.33325 19.9999C3.33325 10.7949 10.7949 3.33325 19.9999 3.33325C29.2049 3.33325 36.6666 10.7949 36.6666 19.9999C36.6666 29.2049 29.2049 36.6666 19.9999 36.6666ZM19.9999 33.3333C23.5361 33.3333 26.9275 31.9285 29.428 29.428C31.9285 26.9275 33.3333 23.5361 33.3333 19.9999C33.3333 16.4637 31.9285 13.0723 29.428 10.5718C26.9275 8.07134 23.5361 6.66659 19.9999 6.66659C16.4637 6.66659 13.0723 8.07134 10.5718 10.5718C8.07134 13.0723 6.66659 16.4637 6.66659 19.9999C6.66659 23.5361 8.07134 26.9275 10.5718 29.428C13.0723 31.9285 16.4637 33.3333 19.9999 33.3333ZM19.9999 16.6666C20.4419 16.6666 20.8659 16.8422 21.1784 17.1547C21.491 17.4673 21.6666 17.8912 21.6666 18.3333V26.6666C21.6666 27.1086 21.491 27.5325 21.1784 27.8451C20.8659 28.1577 20.4419 28.3333 19.9999 28.3333C19.5579 28.3333 19.134 28.1577 18.8214 27.8451C18.5088 27.5325 18.3333 27.1086 18.3333 26.6666V18.3333C18.3333 17.8912 18.5088 17.4673 18.8214 17.1547C19.134 16.8422 19.5579 16.6666 19.9999 16.6666ZM19.9999 14.9999C19.5579 14.9999 19.134 14.8243 18.8214 14.5118C18.5088 14.1992 18.3333 13.7753 18.3333 13.3333C18.3333 12.8912 18.5088 12.4673 18.8214 12.1547C19.134 11.8422 19.5579 11.6666 19.9999 11.6666C20.4419 11.6666 20.8659 11.8422 21.1784 12.1547C21.491 12.4673 21.6666 12.8912 21.6666 13.3333C21.6666 13.7753 21.491 14.1992 21.1784 14.5118C20.8659 14.8243 20.4419 14.9999 19.9999 14.9999Z"
        fill="white" />
    </svg>
    <div class="text-center text-white">Please note</div>
    <a id="btn_dcTimeline_datePopUp_closePopUp_dashboard" class="overlay" role="button" (click)="closePopup()">
    <svg  width="32" height="32" viewBox="0 0 32 32" fill="none" class="backlay" xmlns="http://www.w3.org/2000/svg">
      <path d="M24 8L8 24" stroke="white" stroke-linecap="square" stroke-linejoin="round" />
      <path d="M8 8L24 24" stroke="white" stroke-linecap="square" stroke-linejoin="round" />
    </svg>
  </a>
  </div>
  <div class="sub-title text-center py-3 px-md-3">
    <div class="">Estimated duration of debt counselling</div>
  </div>
</div >
  <div class="content d-flex justify-content-center text-center mt-3 px-3">
    <p class="">This is an estimate only of the remaining duration of your debt counselling journey. The time it takes to complete the process is affected by various factors, such as debt levels, missed or extra payments, changes in life circumstances, negotiation of credit provider terms, etc.</p>
  </div>
</div>